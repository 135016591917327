/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom"
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function IndexHeader() {
  return (
    <>
      <div className="page-header clear-filter"  style={{
            backgroundImage: "url(" + require("assets/img/bg-topo.png") + ")",

          }} >
        <div
          className="page-header-image" >
          <Container fluid className="pb-5">
            <Row className="align-self-center justify-content-center">
              <Col lg={6} xl={4} className="align-self-center order-2 order-lg-1">
                  <h1 className="text-primary bold title">Compre Direto de Fábrica com o Achei Fornecedor!</h1>
                  <p  className="text-black-50 "> Descubra os melhores fornecedores e compre no atacado com facilidade no Achei Fornecedor. Simplificamos a gestão B2B, conectando você a uma ampla rede de fornecedores em diversos segmentos.</p>
                  <p className="text-black-50"> Economize tempo e dinheiro ao encontrar tudo o que precisa em nossa plataforma. 
                  <strong> Maximize suas oportunidades de negócios agora mesmo!</strong></p>
                  <a className="btn-round btn-lg btn btn-secondary" target="_blank" rel='noopener' href="https://app.acheifornecedor.com/cadastro">Cadastre-se</a>
              </Col>
              <Col lg={5} xl={7} className="align-self-center pt-2 pt-lg-5 order-lg-2 order-1">
                <img  className="img-fluid" src={require("../../assets/img/index.svg")} alt="Imagem Principal">
                </img>
              </Col>
            </Row>
          </Container>        
        </div>
        <div className="position-absolute redeSociais d-none d-lg-block">
          <a target="_blank" rel='noopener' href="https://www.facebook.com/Achei-Fornecedor-113737716707790/"><img src={require("../../assets/img/facebook.svg")} className="m-1" alt="Facebook" /></a>
          <a target="_blank" rel='noopener' href="https://www.linkedin.com/company/acheifornecedor"><img src={require("../../assets/img/linkdin.svg")} className="m-1"  alt="Linkdin" /></a>
          <a target="_blank" rel='noopener' href="https://www.instagram.com/acheifornecedor/?igshid=bpakap0k1iae"><img src={require("../../assets/img/instagram.svg")} className="m-1" alt="Instagram" /></a>
        </div>
      </div>
    </>
  );
}



export default IndexHeader;
