import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import RecuperarSenha from "./recuperarSenha"

// core components
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 

const avisar = (e)=>{
  toast.error("Usuário ou senha incorretos", {
    position: toast.POSITION.TOP_CENTER
  });
}



function SignUp() {
  const [recuperarSenha, setarRecuperarSenha] = React.useState(false)
  const [mostrarCode, setarMostrarCode] = React.useState(false)
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [senha, setSenha] = React.useState("");

  const [emailFocus, setEmailFocus] = React.useState(false)
  const [senhaFocus, setSenhaFocus] = React.useState(false)


  const f_email = () => {
    if(!email){
      toast.error("Campo email obrigatório!", {
        position: toast.POSITION.TOP_CENTER
      });
    }else{
      toast.success("Um código de verificação foi enviado para o seu email!", {
        position: toast.POSITION.TOP_CENTER
      });
      setarMostrarCode(true)
    }
  
  }

  const salvarNovaSenha = () => {
    toast.success("Senha alterado com sucesso! Faça o log in.", {
      position: toast.POSITION.TOP_CENTER
    });
    setarMostrarCode(false)
    setarRecuperarSenha(false)
  
  }
  const login = () =>{
    return(
      (
        <>
            <InputGroup className={emailFocus ? "input-group-focus" : "no-border"}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_email-85"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Email..." type="text" className="input-lg" 
              onFocus={()=> setEmailFocus(true)}
              onBlur={()=>setEmailFocus(false)}
              ></Input>
            </InputGroup>

            <InputGroup className={senhaFocus ? "input-group-focus" : "no-border"}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons ui-1_lock-circle-open"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Senha"
                type="password"
                onClick={(e)=>setSenha(e.target.value)}
                onFocus={()=> setSenhaFocus(true)}
                onBlur={()=>setSenhaFocus(false)}
              ></Input>
            </InputGroup>
            <Button
              className="btn btn-lg btn-primary btn-block mt-5"
              color="primary"
              onClick={avisar}
              size="lg"
            >
              Logar
            </Button>

            <p className="text-center cursor-pointer"  onClick={()=> setarRecuperarSenha( !recuperarSenha )}>
            Esqueci minha senha
            </p>
        </>
      )
    )
  }
  return (
    <>
      <ToastContainer />
      <div
        className="section section-signup"
        style={{
          backgroundImage: "url(" + require("../../assets/img/logar-on-top.svg") + ")," + "url(" + require("../../assets/img/logar.svg") + ")", 

        }}
      >
        <Container fluid>
          <Row>
            <Col md={11} className="d-flex justify-content-center justify-content-md-end">
              <Card className="card-signup shadow-none" >
                <Form action="" className="form" method="">
                <CardHeader className="text-center">
                  <Link to="/"><img src={require("../../assets/img/logo@2x.png")} style={{maxWidth: 100}} alt="Logo Achei Fornecedor"/></Link>
                  <CardTitle  tag="p">
                    {recuperarSenha ? ( mostrarCode  ? `O código de verificação foi enviado para ${email}` : "Qual o seu e-mail cadastrado?") : 'Seja Bem-vindo!'}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {recuperarSenha ? 
                  <RecuperarSenha 
                    mostrarCode={mostrarCode} 
                    code={code} 
                    setCode={setCode} 
                    salvarNovaSenha={salvarNovaSenha} 
                    setEmail={setEmail} 
                    f_email={f_email}/> 
                  :
                  login()}
                </CardBody>
                <CardFooter className="text-center pb-0">
                  <p className="text-center">
                    Ainda não tem conta? <Link to="/cadastro"><strong>Cadastre-se</strong></Link>
                  </p>
                </CardFooter>
              </Form>
              </Card>
            </Col>
          </Row>
          <div className="col text-center">

          </div>
        </Container>
      </div>
    </>
  );
}

export default SignUp;
