/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function Sobre() {
  return (
    <>
      <div className="page-header clear-filter"  style={{
            backgroundImage: "url(" + require("assets/img/bg-topo.png") + ")",

          }} >
        <div
          className="page-header-image" >
          <Container fluid className="pb-5">
            <Row className="align-self-center justify-content-center">
              <Col lg={6} xl={4} className="align-self-center order-2 order-lg-1">
                  <h1 className="text-primary bold title">Contato</h1>
                  <p  className="text-black-50"><strong>e-mail</strong>: contato@acheifornecedor.com</p>
                  <p className="text-black-50 "><strong>Endereço de correspondência:</strong> Rua 9 Norte Lote 5 e Rua das Pitangueiras Lote 6 Lojas 11/12 Ed. Easy Parte 152 CEP 71.908-540, Aguas Claras - Distrito Federal</p>

              </Col>
              <Col lg={5} xl={7} className="align-self-center pt-2 pt-lg-5 order-lg-2 order-1">
                <img  className="img-fluid" src={require("../../assets/img/contato.svg")}>
                </img>
              </Col>
            </Row>
          </Container>        
        </div>

      </div>
    </>
  );
}



export default Sobre;
