import React from 'react';
import {Container, Col, Row} from "reactstrap"
const Comissao = () =>{
    return(
        <Container fluid> 
            <Row className="justify-content-end" >
              <Col  lg={4} className="align-self-center">
                <h1 className="text-primary bold title" >Anuncie gratuitamente como fornecedor no atacado</h1>
                <p className="text-black-50 mb-1">Esteja sempre disponível para seus clientes, expandindo sua presença online em nossa plataforma.</p>
              </Col>
              <Col lg={6} className="pl-3">
                <img  className="img-fluid" src={require("../../../assets/img/gratis.svg")}  style={{marginLeft: "-15px"}} alt="imagem todos juntos">
                </img>
              </Col>
            </Row>
          </Container>  
    )
}

export default Comissao