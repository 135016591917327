import React from "react";
import { NavLink as NavLinkRouter, Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 40 ||
        document.body.scrollTop > 40
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 41 ||
        document.body.scrollTop < 41
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, );
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="white" >
        <Container fluid>
          <div className="navbar-translate">
            <NavLinkRouter to="/">
              <NavbarBrand
                target="_blank"
                id="navbar-brand"
              >
                <img src={require("../../assets/img/logo@2x.png")} style={{maxWidth: 100}} alt="Logo Achei fornecedor"/>
              </NavbarBrand>
            </NavLinkRouter>

            <UncontrolledTooltip target="#navbar-brand">
              Achei fornecedor
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav  
              onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
              navbar className="mr-auto">
              <NavItem>
                  <NavLinkRouter className="nav-link" to="/sobre">Sobre</NavLinkRouter>
              </NavItem>
              <NavItem>
                  <NavLinkRouter className="nav-link" to="/Planos">Planos</NavLinkRouter>
              </NavItem>
              <NavItem>
                  <NavLinkRouter className="nav-link" to="/contato">Contato</NavLinkRouter>
              </NavItem>
              <NavItem>
                  <a className="nav-link" href="https://acheifornecedor.blog.br">Blog</a>
              </NavItem>
              <NavItem>
                  <a className="nav-link" href="https://adoptprodstorage.blob.core.windows.net/adopt-prod/organization/d73cec9d-6867-405c-ae7e-7f93789a914c/disclaimer/8ca73fbc-c199-43fb-9156-e0b055041570/terms/01HXSFDKNGVGXA2R93D5BSRFS7.pdf">Termos de Uso</a>
              </NavItem>
              <NavItem>
                  <a className="nav-link" href="https://adoptprodstorage.blob.core.windows.net/adopt-prod/organization/d73cec9d-6867-405c-ae7e-7f93789a914c/disclaimer/8ca73fbc-c199-43fb-9156-e0b055041570/policy/01HXSFDYKR3E5J1CN52B3D7R3B.pdf">Politica de Privacidade</a>
              </NavItem>
    
            </Nav>
            <Nav navbar >
            <NavItem>
              
                <a
                  className="btn nav-link btn-neutral btn-round text-center text-primary"
                  href="https://app.acheifornecedor.com/login"
                  target="_blank"
                  rel="noopener noreferrer"> 
                  Logar
                </a>
              </NavItem>

              <NavItem>
              <a
                  className="btn nav-link btn-neutral btn-round text-center text-primary"
                  href="https://app.acheifornecedor.com/cadastro"
                  target="_blank"
                  rel="noopener noreferrer"> 
                  Cadastre-se
                </a>
              </NavItem>
              </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
