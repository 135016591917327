import React from 'react';
import {Container, Col, Row} from "reactstrap"
const Juntos = () =>{
    return(
        <Container fluid>
            <Row className="justify-content-end" >

              <Col  lg={4} className="align-self-center">
                <h1 className="text-primary bold title">Propósito</h1>
                <p  className="text-black-50">O Achei Fornecedor tem como propósito conectar empreendedores de maneira eficaz e facilitada, impulsionando o crescimento dos negócios.
                 Explore nossa plataforma que reúne fornecedores de todos os segmentos, onde quem fornece pode anunciar produtos e serviços, ganhar mais visibilidade e fechar negócios.
                  Economize tempo e recursos ao solicitar cotações e fazer pedidos de forma online e rápida. Monte sua lista de fornecedores e negocie diretamente, eliminando intermediários. Seu sucesso é nosso objetivo!</p>
              </Col>
              <Col lg={6}>
                <img  className="img-fluid" src={require("../../../assets/img/proposito.svg")}  style={{marginLeft: "-15px"}} alt="Proposito">
                </img>
              </Col>
            </Row>
          </Container>  
    )
}

export default Juntos