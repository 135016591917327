import React from "react";
import Layout from "../../components/layout"
import BannerTopo from "../../components/banner"
import Juntos from "./parts/juntos.js"
import Beneficios from "./parts/beneficios"
import Anuncio from "./parts/anuncio"
import {prepararTela} from "../../uteis/index"
import Lista from "./parts/beneficiosCima"
import LoadingPage from "../../components/loader/page"
import Comisao from "./parts/comissao"
function Index() {
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    prepararTela()
    setTimeout(()=>{
      setLoading(false)
    }, 2000)
  });
  return (
      <Layout >
        <LoadingPage loading={loading}/>
        <BannerTopo />
        <Juntos />
        <Comisao />
        <Anuncio />
        <Lista />
        <Beneficios />
        {/* <Parceiros />*/}
      </Layout>
  );
}

export default Index;
