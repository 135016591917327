import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {validaCpfCnpj} from "../../uteis/index"
import validator from "validator"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Input,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import {API, apiConfig} from "../../config/api.config"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {cnpjMask} from "../../uteis/index"


// core components
function Cadastro() {

  const [tipoCadastro, setartipoCadastro] = useState(null);
  // setar os valores de cada campo
  const [cnpj, setCnpj] = useState("")
  const [companyName, setComanyName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [c_password, setC_password] = useState("")
  const [btnCarregando, setBtnCarregando] = useState(false)
  const [termodeUso, setTermoDeUso] = useState(false);
  const [termodePrivacidade, setTermoDePrivacidade] = useState(false);


  const clearFields = () =>{
    setCnpj("")
    setComanyName("")
    setEmail("")
    setPassword("")
    setC_password("")
  }
  const msg = (msg) =>{
    toast.error(
      msg,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 15000,
      }
    );
  }

  const changePrivacidade = (e) => {
    setTermoDePrivacidade(!termodePrivacidade)
  }

  const changeTermos = (e) => {
    setTermoDeUso(!termodeUso)
  }

  const thereErros = ()=>{
      if(!cnpj || !validaCpfCnpj(cnpj)){
        msg("Campo CNPJ é obrigatório ou nāo é válido")
        return true
      }

      if(cnpj.length < 18){
        msg("O Campo CNPJ não ė valido")
        return true
      }
  
      if(!companyName){
        msg("Preencha o campo Razão social")
        return true
      }
  
      if(!email ||(!validator.isEmail(email))){
        msg("Campo EMAIl é obrigatório ou a formatação do mesmo não esta adequada")
        return true
      }
  
  
      if( password !== c_password || password.length < 5){
        msg("Campos senha e sua confirmação não coincidem e devem conter mais de cinco caracteres")
        return true
      }

      if( !termodeUso ){
        msg("Aceite nosso termo de uso")
        return true
      }

      if( !termodePrivacidade ){
        msg("Aceite nosso termo de privacidade")
        return true
      }

    return false
  }
  const cadastrar = async(e) => {

    const fieldsErro = thereErros()
    if(fieldsErro)
      return 

    setBtnCarregando(true)

    try {
      const url = `${API}/auth/register?cnpj=${cnpj}&company_name=${companyName}&email=${email}&profile=${tipoCadastro === "Fornecedor" ? 1 : 2}&c_password=${password}&password=${password}&terms_of_use=${termodeUso ? 1 : 0}&privacy_policy=${termodePrivacidade ? 1 : 0}`
      const result  = await fetch(url, apiConfig)
                                    .then(response =>{
                                      return response.json()
                                    })
                                    .then((data)=>{
                                        console.log(data)
                                        return data
                                    })
                                    .catch(err=>{
                                      msg("Ops! Estamos com problema momentâneo tente novamente mais tarde!")
                                      return err
                                    })
      setBtnCarregando(false)
      if(!result)
        return
        
      if(result.resposta.status === 500){
        msg(result.resposta.conteudo.message)
      }
      if(result.resposta.conteudo.error){
        for (let item in result.resposta.conteudo.messages){
          if(Array.isArray(result.resposta.conteudo.messages[item]))
            msg(result.resposta.conteudo.messages[item][0])
          else
            msg(result.resposta.conteudo.messages)
        }
      }else{
        toast.success(
          "Cadastro feito com sucesso para finalizar apenas confirme seu e-mail, clicando no link que enviamos para ele",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          }
        );
        clearFields()
      }

    } catch (error) {
      console.log("algo de errado", error)
    }

  };
  return (
    <>

      <div
        className="section section-cadastrar"
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/cadastro.svg") +
            ")," +
            "url(" +
            require("../../assets/img/logar.svg") +
            ")",
        }}
      >      <ToastContainer />
        <Container fluid>
          <Row>
            <Col
              md={11}
              className="d-flex justify-content-center justify-content-md-end"
            >
              <Card className="card-signup shadow-none">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <Link to="/">
                      <img
                        src={require("../../assets/img/logo@2x.png")}
                        style={{ maxWidth: 100 }}
                        alt="logo Achei Fornecedor"
                      />
                    </Link>
                    <CardTitle className="" tag="p">
                      {tipoCadastro ? (
                        <>
                          <i
                            className="fas fa-arrow-left mr-1"
                            onClick={() => setartipoCadastro(null)}
                            style={{ opacity: 0.5 }}
                          ></i>{" "}
                          Cadastro
                        </>
                      ) : (
                        "Se cadastrar como?"
                      )}
                    </CardTitle>
                  </CardHeader>
                  {!tipoCadastro ? (
                    <div className="d-flex justify-content-center op-cadastro">
                      <div
                        className="flex-grow-1 p-1"
                        onClick={() => setartipoCadastro("Comprador")}
                      >
                        <img
                          className="img-fluid"
                          src={require("../../assets/img/cliente.svg")}
                          alt="Selecionar Comprador"
                        />
                        <p className="text-center bold">Comprador</p>
                      </div>
                      <div
                        className="flex-grow-1 p-1"
                        onClick={() => setartipoCadastro("Fornecedor")}
                      >
                        <img
                          className="img-fluid"
                          src={require("../../assets/img/fornecedor.svg")}
                          alt="Selecionar Fornecedor"
                        />
                        <p className="text-center bold">Fornecedor</p>
                      </div>
                    </div>
                  ) : (
                    <CardBody>
                      <FormGroup>
                        <Input maxLength={18} value={cnpj} placeholder="CNPJ" type="text" onChange={(e)=>setCnpj( cnpjMask(e.target.value))}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="Razão social" type="text" value={companyName} onChange={(e)=>setComanyName(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="e-mail" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="Senha" value={password} type="password" onChange={(e)=>setPassword(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={c_password}
                          placeholder="Confirmação de senha" type="password" onChange={(e)=>setC_password(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" name="check1" id="exampleCheck"  value={termodeUso}  onChange={changeTermos}/>
                          <span className="form-check-sign small"></span>
                          <a target="_blank" rel="noopener noreferrer"  href="https://adoptprodstorage.blob.core.windows.net/adopt-prod/organization/d73cec9d-6867-405c-ae7e-7f93789a914c/disclaimer/8ca73fbc-c199-43fb-9156-e0b055041570/terms/01HXSFDKNGVGXA2R93D5BSRFS7.pdf">Termo de uso</a>
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox"name="check2" id="exampleCheck2" value={termodePrivacidade} onChange={changePrivacidade}></Input>
                          <span className="form-check-sign small"></span>
                          <a target="_blank" rel="noopener noreferrer"  href="https://adoptprodstorage.blob.core.windows.net/adopt-prod/organization/d73cec9d-6867-405c-ae7e-7f93789a914c/disclaimer/8ca73fbc-c199-43fb-9156-e0b055041570/policy/01HXSFDYKR3E5J1CN52B3D7R3B.pdf">Politicas de privacidade</a>
                        </Label>
                      </FormGroup>
                      <Button 
                        className="btn btn-lg btn-lg bg-primary text-white btn-block mt-5"
                        color="primary"
                        onClick={cadastrar}
                        size="lg"
                        disabled={btnCarregando}
                      >
                        {btnCarregando ? <i className="fas fa-spinner fa-pulse mr-1"></i> : null}
                        Cadastrar
                      </Button>
                    </CardBody>
                  )}
                </Form>
                <p className="text-center mt-3">
                  Já tem uma conta?{" "}
                  <a href="https://app.acheifornecedor.com/login" target="_blank" rel="noopener noreferrer" >
                    <strong>logar</strong>
                  </a>
                </p>
              </Card>
            </Col>
          </Row>
          <div className="col text-center"></div>
        </Container>
      </div>
    </>
  );
}

export default Cadastro;
