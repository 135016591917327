import React from 'react';
import {Container, Col, Row, Card, CardBody} from "reactstrap"
import Slider from "react-slick";

const parceiros = [
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  },
  {
    texto: "../../../assets/img/logo-default.svg"
  }
]

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  autoplay: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const Parceiros = () =>{
    return(
        <Container className="pt-4 pb-2 pb-4">
            <Row className="justify-content-center">
              <Col sm="8">
                <h1 className="text-primary bold title text-left text-sm-center mt-2">Conecte-se a Fornecedores de múltiplos segmentos: </h1>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <Card>
                  <CardBody className="text-center">
                    <i className="fas fa-tractor fa-5x text-secondary"></i>
                    <p className="text-black-50">Encontre produtores e fornecedores de insumos e matérias-primas</p>
                  </CardBody >
                </Card>
              </Col>
              <Col sm="4">
                <Card>
                  <CardBody className="text-center">
                    <i className="fas fa-building fa-5x text-secondary"></i>
                    <p className="text-black-50">Descubra fornecedores industriais para o processamento, distribuição e venda em atacado com nosso mercado eletrônico.</p>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="4">
                <Card>
                  <CardBody className="text-center">
                    <i className="fas fa-shipping-fast fa-5x text-secondary"></i>
                    <p className="text-black-50">Encontre quem fornece serviços para empresas com nossa plataforma B2B.</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>  
    )
}

export default Parceiros

