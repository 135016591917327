import React from 'react';
import {Container, Col, Row} from "reactstrap"
import Slider from "react-slick";

const beneficios = [
  {
    texto: "Desenvolva sua rede de contatos"
  },
  {
    texto: "E-Procurement: Solução eficiente para gerenciamento de compras empresariais"
  },
  {
    texto: "Construa sua lista de Fornecedores Gratuitamente"
  },  
  {
    texto: "Negocie com Eficiência e Rapidez"
  },
  {
    texto: "Não perca tempo esperando pelo Representante Comercial"
  },
  {
    texto: "Obtenha Informações Confiáveis sobre Produtos e Serviços"
  },
  {
    texto: "Encontre Fornecedores confiáveis de forma fácil"
  },
  {
    texto: "Aumente sua Visibilidade como Fornecedor na nossa plataforma de negócios B2B"
  }
]

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const Beneficios = () =>{
    return(
        <Container className="pt-2 pb-2">
            <Row>
              <Col>
                <h1 className="text-primary bold title text-left text-sm-center text-primary  mt-2 mb-2 text-uppercase">benefícios</h1>
                <p className="text-black-50 text-left text-sm-center">Conheça alguns benefícios ao fazer parte da nossa plataforma.</p>
              </Col>
            </Row>
            <div className="mb-5">
              <Slider {...settings}>
                  {beneficios.map((item, i)=>{
                    return(
                      <div key={Math.random()} className="text-center beneficios">
                        <div className="moeda m-auto" style={{backgroundImage:  `url(${require('../../../assets/img/moeda.svg')})` }}>
                          <h1 className="bold text-primary display-4" >{i + 1}</h1>
                        </div>
                        <h4 className="text-center text-primary bold h4">{item.texto}</h4>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </Container>  
    )
}

export default Beneficios

