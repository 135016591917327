export const API  = "https://api.acheifornecedor.com/api/v1.0.0"
export const API_FORGET = "https://api.acheifornecedor.com/api/forgot"

export const BASE_URL_PRODUCTS  = "auth/products"
export const BASE_URL_SERVICES  = "auth/services"

export const URL_TO_IMAGES  = "http://api.acheifornecedor.com/storage"

export const apiConfig  = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8'
    }
}