/*eslint-disable*/
import React, {useEffect, useRef} from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components
import lottie from "lottie-web"
import animation from "../../assets/json/gift2"
function Sobre() {
  const container  = useRef(null)
  useEffect(() => {

    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: animation
    })


  }, []);
  return (
    <>
      <div className="page-header clear-filter"  style={{
            backgroundImage: "url(" + require("assets/img/bg-topo.png") + ")",
            minHeight: "100vh"
          }} >
        <div className="page-header-image">
            <Container fluid className="pb-5"> 
            <Row className="align-self-center justify-content-center">
              <Col lg={6} xl={4} className="align-self-center order-2 order-lg-1">
                <h1 className="text-primary bold title">Sempre Gratuito! </h1>
                <p className="text-black-50"> 
                  Cadastre-se no <strong>Achei Fornecedor</strong> hoje mesmo e aproveite nosso <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong>.
                </p>
                <p className="text-black-50">
                  Além disso, estamos preparando novidades emocionantes para o futuro! Em breve, iremos implementar dois novos planos, repletos de recursos adicionais e benefícios exclusivos, para aqueles que desejam um pouco mais.
                </p>
                <p className="text-black-50">
                  Mas não se preocupe! O <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> continuará disponível, permitindo que você continue utilizando a plataforma sem custo algum, mesmo após a introdução dos planos pagos.
                </p>
                <p className="text-black-50">
                  Nosso objetivo é garantir que todos os usuários tenham uma experiência incrível no <strong>Achei Fornecedor</strong>, independentemente do plano escolhido.<br />
                  Esperamos que você aproveite ao máximo a plataforma, seja no <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> ou nos futuros planos pagos.
                </p>
                <p className="text-black-50">
                  Participe agora e descubra como o <strong>Achei Fornecedor</strong> pode ajudá-lo a encontrar fornecedores de confiança e alavancar seus negócios, sem nenhum custo para você! <strong>Estamos ansiosos para tê-lo conosco nesta jornada de sucesso.</strong>
                </p>
                <p className="text-black-50">
                  <small></small>
                </p>
              </Col>
              <Col lg={5} xl={7} className="align-self-center pt-2 pt-lg-5 order-lg-2 order-1">
                <div ref={container}></div>
              </Col>
            </Row>
          </Container>
        </div>

      </div>
    </>
  );
}



export default Sobre;
