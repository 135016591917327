import React from 'react';
import {Container, Col, Row} from "reactstrap"
const Juntos = () =>{
    return(
        <Container fluid>
            <Row >
              <Col lg={6}>
                <img  className="img-fluid" src={require("../../../assets/img/juntos.png")}  style={{marginLeft: "-15px"}} alt="imagem todos juntos">
                </img>
              </Col>
              <Col  lg={4} className="align-self-center">
                <h1 className="text-primary bold title">Potencialize seu negócio</h1>
                <p  className="text-black-50">Nosso mercado eletrônico B2B impulsiona vendas e visibilidade para quem fornece, oferecendo aos compradores abastecimento e acesso a preços competitivos. Proporcionamos informações estratégicas para decisões de compra assertivas.
                 Desfrute de um ambiente dinâmico para suas demandas comerciais B2B, com acesso ágil e descomplicado.</p>
              </Col>
            </Row>
          </Container>  
    )
}

export default Juntos