import React from 'react';
import {Container, Col, Row} from "reactstrap"
import imageEtica from "../../../assets/img/etica.svg"
import transparencia from "../../../assets/img/transparencia.svg"
import inovacao from "../../../assets/img/inovacao.svg"
import integridade from "../../../assets/img/integridade.svg"
import cooperacao from "../../../assets/img/cooperacao.svg"
const valores = [
  {
    texto: "Ética",
    img: imageEtica
  },
  {
    texto: "Transparência",
    img:  transparencia
  },
  {
    texto: "Empreendedorismo e inovação",
    img: inovacao
  },
  {
    texto: "Integridade",
    img:  integridade
  },
  {
    texto: "Cooperação",
    img: cooperacao
  }
]


const Valores = () =>{
    return(
        <Container className="pt-2 pb-2">
            <Row>
              <Col>
                <h1 className="text-primary bold title text-center mt-2 text-uppercase mb-2">Nossos Valores</h1>
                <p className="text-black-50 text-center">O que não abrimos mão no nosso negócio.</p>
              </Col>
            </Row>
            <Row className="mb-5">

                  {valores.map((item, i)=>{
                    return(
                      <Col key={Math.random()} className="text-center beneficios">
                        <div className="moeda m-auto" style={{backgroundImage:  `url(${item.img})` }}>
                        </div>
                        <h4 className="text-center text-primary bold h4">{item.texto}</h4>
                      </Col>
                    )
                  })}

            </Row>
          </Container>  
    )
}

export default Valores

