/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function DarkFooter() {
  return (
    <footer className=" bg-white pt-4">
      <Container>
        <Row>
          <Col className="align-self-center text-center">
            <img className="m-auto" src={require("../../assets/img/logo@2x.png")} style={{maxWidth: 120}}/>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2 mb-2">
            <a target="_blank" rel='noopener' href="https://www.facebook.com/Achei-Fornecedor-113737716707790/"><img src={require("../../assets/img/facebook.svg")} className="m-1" alt="Facebook" /></a>
            <a target="_blank" rel='noopener' href="https://www.linkedin.com/company/acheifornecedor"><img src={require("../../assets/img/linkdin.svg")} className="m-1"  alt="Linkdin" /></a>
            <a target="_blank" rel='noopener' href="https://www.instagram.com/acheifornecedor/?igshid=bpakap0k1iae"><img src={require("../../assets/img/instagram.svg")} className="m-1" alt="Instagram" /></a>
        </Row>
        <Row className="justify-content-center mt-2 mb-2 text-primary bold title">
            <a target="_blank" rel='noopener' href="">Termos de uso</a> &nbsp;|&nbsp;
            <a target="_blank" rel='noopener' href="">Política de privacidade</a> &nbsp;|&nbsp;
            <a target="_blank" rel='noopener' href="">Limitação de Responsabilidade</a>
          </Row>

        <Row>

         <div className="copyright text-center m-auto text-black-50" id="copyright">
            <p className="mb-0">
            <small>2021 © Todos os direitos reservados ACHEI FORNECEDOR PORTAL DE SERVIÇOS B2B LTDA.  CNPJ 40.341.475/0001-66</small>

            </p>
              
          </div>
        </Row>
    
      </Container>
    </footer>
  );
}

export default DarkFooter;
