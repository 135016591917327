import React from 'react'
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom"

import MainPage from '../views/mainPage/Index'
import Page404 from '../views/404/Index'
import LogIn from "../views/login/Index"
import Cadastro from "../views/cadastro/Index"
import Sobre from "../views/sobre/Index"
import Preco from "../views/preco/Index"
import Contato from "../views/contato/Index"

import {TransitionGroup, CSSTransition} from "react-transition-group"

const Routes = (props)=>{
    return(
        <BrowserRouter>
          <Route render={({location})=>{
            console.log(location)
            return(
              <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="fade">
              <Switch location={location}>
                <Route path="/cadastro" render={(props) => <Cadastro {...props} />} exact/>
                <Route path="/sobre" render={(props) => <Sobre {...props} />} exact/>
                <Route path="/planos" render={(props) => <Preco {...props} />} exact/>
                <Route path="/contato" render={(props) => <Contato {...props} />} exact/>
                
                <Route path="/" render={(props) => <MainPage {...props} />}  exact/>
                <Route path="/404" render={(props) => <Page404 {...props} />}  exact/>
                <Route path="/sitemap.xml" component={() => { 
                    window.location.href = "/sitemap.xml";
                    return null;
                  }} />
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
              </Switch>
              </CSSTransition>
            </TransitionGroup>
            )
          }} />



      </BrowserRouter>
    )
}


export default Routes