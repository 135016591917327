import React from "react";
import Layout from "../../components/layout"
import {Container, Col, Row, Button} from "reactstrap"
import {Link} from "react-router-dom"


function Sobre() {
  React.useEffect(() => {
    console.log("sidebar-collapse")
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
      <Layout >
        
      <div className="page-header clear-filter"  style={{
            backgroundImage: "url(" + require("assets/img/bg-topo.png") + ")",

          }} >
        <div
          className="page-header-image" >
          <Container fluid className="pb-5">
            <Row className="align-self-center justify-content-center">
              <Col lg={6} xl={4} className="align-self-center order-2 order-lg-1">
                  <h1 className="text-primary bold title">Ops!! Página não encontrada</h1>
                  <Link to="/">
                    <Button className="btn-round btn-lg">Ir para a Home</Button>

                  </Link>
              </Col>
              <Col lg={5} xl={7} className="align-self-center pt-2 pt-lg-5 order-lg-2 order-1">
                <img  className="img-fluid" src={require("../../assets/img/topo-404.svg")} alt="404 erro">
                </img>
              </Col>
            </Row>
          </Container>        
        </div>
      </div>
      </Layout>
  );
}

export default Sobre;
