import React from 'react';
import {Container, Col, Row} from "reactstrap"
const Comissao = () =>{
    return(
        <Container fluid className="mt-2"> 
            <Row >
              <Col lg={6} className="order-1 order-lg-0">
                <img  className="img-fluid" src={require("../../../assets/img/juntos.png")}  style={{marginLeft: "-15px"}} alt="imagem todos juntos">
                </img>
              </Col>
              <Col  className="order-0 order-lg-1" lg={4} className="align-self-center">
                <h1 className="text-primary bold title mb-1" ><br />Venda sem comissão</h1>
                <p  className="text-black-50 mb-1"> Sem comissões nas vendas, aumente seus lucros anunciando seus produtos. <strong>Conecte-se a clientes e expanda seus negócios!</strong></p>
              </Col>
            </Row>
          </Container>  
    )
}

export default Comissao