/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
// core components

function Sobre() {
  return (
    <>
      <div className="page-header clear-filter"  style={{
            backgroundImage: "url(" + require("assets/img/bg-topo.png") + ")",
            minHeight: "calc(100vh - 150px)"
          }} >
        <div
          className="page-header-image" >
          <Container fluid className="pb-5">
            <Row className="align-self-center justify-content-center">
              <Col lg={6} xl={5} className="align-self-center order-2 order-lg-1">
                  <h1 className="text-primary bold title text-uppercase"> Impulsione seus negócios com o Achei Fornecedor</h1>
                  <p  className="text-black-50 "> Procurando quem fornece produtos e serviços? O<strong> Achei Fornecedor</strong> é sua solução B2B. 
                  Descubra atacadistas em diversos segmentos, faça pedidos e negocie. Economize tempo e dinheiro solicitando cotações e fazendo pedidos diretamente em nosso marketplace.</p>
              </Col>
              <Col lg={5} xl={6} className="align-self-center pt-2 pt-lg-5 order-lg-2 order-1">
                <img  className="img-fluid" src={require("../../assets/img/topo-sobre.svg")}>
                </img>
              </Col>
            </Row>
          </Container>        
        </div>
        

      </div>
    </>
  );
}



export default Sobre;
