import React from "react";
import Layout from "../../components/layout"
import BannerTopo from "../../components/banner/sobre"
import Proposito from "./parts/proposito"
import Valores from "./parts/valores"
import Juntos from "./parts/juntos"

function Sobre() {
  React.useEffect(() => {
    console.log("sidebar-collapse")
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
      <Layout >
        <BannerTopo />
        <Juntos />
        <Proposito />
        <Valores />
      </Layout>
  );
}

export default Sobre;
