import React from 'react';
import {Container, Col, Row} from "reactstrap"
import ReactPlayer from "react-player"

const Juntos = () =>{
    return(
        <Container>
            <Row className="justify-content-center mt-1" >
              <Col lg={8}>
                <h1 className="text-primary bold title text-center ">Descubra quem fornece para empresas</h1>
                  <p className="text-center text-black-50">Simplifique o comércio B2B com uma plataforma eficiente de mercado eletrônico para empresas - 
                  Conecte-se a uma rede de fornecedores e expanda seus negócios!</p>
                  <div className="embed-responsive embed-responsive-16by9 border mb-3">
                      <ReactPlayer
                          className='embed-responsive-item'
                          width="100%"
                          height="auto"
                          controls={true}
                          url="https://www.youtube.com/watch?v=qczFr8rF-Zc"
                      />
                  </div>
                </Col>
            </Row>
          </Container>  
    )
}

export default Juntos